import React, { useState, useEffect, useRef } from "react";
import { testimonials } from "./Testimonials.helper";
import Slider from "react-slick";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { mobileViewBreakpoint, smallMobileViewBreakpoint } from "../helper";
import Container from "@material-ui/core/Container";
import HeadingCTA from "../HeadingCTA/HeadingCTA";
import DoubleQuotesIcon from "../../assets/images/testimonials/double-quotes.svg";
import Typography from "../Typography/Typography";
import Button from "../Button/Button";
import ArroSliderNextWhite from "../../assets/images/testimonials/nexticonwhite.svg";
import ArroSliderPrev from "../../assets/images/testimonials/previcon.svg";
import ArroSliderPrevWhite from "../../assets/images/testimonials/previconwhite.svg";
import ArroSliderNext from "../../assets/images/testimonials/nexticon.svg";

const useStyles = makeStyles(theme => ({
  testimonialsWrapper: {
    overflow: "hidden",
    maxWidth: 1234,
    margin: "0 auto 160px auto",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginBottom: "-140px",

      "& .MuiContainer-root": {
        padding: 0
      },
      "& .slick-slider": {
        "&:last-child": {
          "& .slick-list": {
            top: "-50px",
            left: "auto",
            paddingLeft: "0px!important"
          }
        }
      }
    },

    '& [class*="imageSliderWrapper"]': {
      paddingBottom: 0,

      "& .slick-slider > img": {
        top: "auto",
        bottom: "0",
        left: "unset",
        transform: "unset",
        right: 0,

        "&:hover": {
          right: -10
        }
      },

      "& .slick-slider > img[class*='prevArrow']": {
        right: 76,

        "&:hover": {
          right: 66
        }
      }
    }
  },
  testimonialContainer: {
    paddingLeft: 32,
    paddingRight: 32
  },
  testimonialWrapper: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    outline: "none",

    "& > img": {
      marginRight: "-90px"
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      flexDirection: "column",

      "& > img": {
        marginRight: "unset",
        marginBottom: "-50px",
        width: "100%"
      }
    }
  },
  content: {
    width: "100%",
    height: 480,
    padding: 40,
    borderRadius: "6px",
    marginRight: "12px",
    zIndex: 9999,

    "& > img": {
      marginBottom: "unset"
    },

    "& > h5": {
      marginTop: "24px",
      marginBottom: "16px",
      fontSize: "18px"
    },

    "& > p": {
      marginBottom: "24px",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        fontSize: "14px",
        lineHeight: "20px"
      }
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "24px",
      height: "auto",
      minHeight: "260px",
      width: "calc(100% - 15px)",
      marginRight: "unset",
      marginBottom: "12px",
      marginTop: "0px",
      position: "relative",
      zIndex: 9999,
      border: "1px solid #efefef",
      background: "#fff",
      boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.05)",

      "& > img": {
        width: 30,
        height: 24,
        marginBottom: "16px"
      },

      "& > p:nth-child(2)": {
        marginTop: "16px",
        marginBottom: "16px"
      },

      "& > p:last-child": {
        marginBottom: "24px"
      }
    }
  },
  headingCTAWrapper: {
    marginTop: "160px",
    marginBottom: "80px",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginTop: "0px",
      marginBottom: "32px",
      paddingLeft: "16px",
      paddingRight: "16px"
    }
  },

  "& .slick-slider": {
    "&:last-child": {}
  },
  sliderContainer: {
    [theme.breakpoints.up(mobileViewBreakpoint)]: {
      display: "flex",
      "& .slick-slider": {
        width: "57%",

        "&:last-child": {
          width: "51%",
          "& .slick-arrow": {
            background: "#fff",
            border: "2px solid #CC4A01",
            boxShadow: "0px 6px 10px rgb(66 75 90 / 20%)",
            width: "42px",
            height: "42px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%",

            "& > svg": {
              fill: " #CC4A01",
              width: "35px",
              height: "32px",
              transition: "all ease 0.4s"
            },
            "&:hover": {
              "& > svg": {
                fill: " #fff",
                transition: "all ease 0.4s"
              }
            }
          },
          "& .slick-list": {
            top: "48px",
            left: "-100px",
            border: "1px solid #efefef",
            background: "#fff",
            boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.05)",
            borderRadius: "6px"
          },
          "& .slick-prev": {
            top: "auto",
            bottom: "-8px",
            left: "auto",
            right: "160px",
            background: `#ffffff url(${ArroSliderPrev})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",

            transition: "all ease 0.5s",
            "&:before": {
              content: "none"
            },
            "&:hover": {
              backgroundImage: ``,
              background: `#CC4A01 url(${ArroSliderPrevWhite})`,

              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
            }
          },
          "& .slick-next": {
            top: "auto",
            bottom: "-8px",
            right: "96px",
            background: `#ffffff url(${ArroSliderNext})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",

            transition: "all ease 0.5s",
            "&:before": {
              content: "none"
            },
            "&:hover": {
              backgroundImage: ``,
              background: `#CC4A01 url(${ArroSliderNextWhite})`,

              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
            }
          }
        }
      }
    }
  }
}));

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      CpsImage: file(relativePath: { eq: "testimonials/cpsaviation.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }

      RallyDakarImage: file(
        relativePath: { eq: "testimonials/dakarrally.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }

      YasMarinaImage: file(
        relativePath: { eq: "testimonials/yasmarinacircuit.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }

      NBAImage: file(relativePath: { eq: "testimonials/nba.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  const testimonials = [
    {
      picture: data.RallyDakarImage.childImageSharp.gatsbyImageData,
      link: "/case-studies/dakar-rally/",
      name:
        "Christophe PUGINIER - Operations & Logistics Manager – Dakar Rally",
      description: (
        <>
          MOTUS | ONE has been part of the success of this edition of Dakar
          Rally 2021 by providing high-quality transport service. No delay, good
          coordination, quick adaptation, very flexible. Dakar Rally is a big
          logistic challenge and you’ve been able to reach it!
        </>
      )
    },
    {
      picture: data.YasMarinaImage.childImageSharp.gatsbyImageData,
      link: "/case-studies/abu-dhabi-formula-one/",
      name: "Iana Oberholzer - Yas Marina Circuit",
      description: (
        <>
          Working with MOTUS | ONE was such an incredible experience. From day
          1, they really lightened the burden of the logistics of such a huge
          event and we could focus on the things that need our attention. Truly
          such a great experience.
        </>
      )
    },
    {
      picture: data.CpsImage.childImageSharp.gatsbyImageData,
      name: "François Campos - CPS-Aviation",
      description: (
        <>
          Your flexibility, your understanding, your major assistance with the
          crews were highly appreciated. Most of all, the courtesy of all your
          staff and your personal friendliness were nice to live with.
        </>
      )
    },

    {
      picture: data.NBAImage.childImageSharp.gatsbyImageData,
      //link: "/case-studies/nba-india-games/",
      name: "Reiner Lamptey, NBA",
      description: (
        <>
          I have been working with MOTUS | ONE for a number of years. They
          provide excellent service and assistance. They are a customer focused
          company that always goes the extra mile to provide the best service
          possible. They makes it easy to do business with them because their
          responses to inquiries are quick, they are easy to communicate with
          whether through email or by phone. They make sure the quality of
          service is top notch and always deliver top notch technology &
          equipment no matter the market. I would definitely recommend MOTUS |
          ONE for all of your transportation needs!
        </>
      )
    }
  ];
  const classes = useStyles();
  const theme = useTheme();

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const [nav1State, setNav1State] = useState();
  const [nav2State, setNav2State] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  useEffect(() => {
    setNav1State(slider1.current);
    setNav2State(slider2.current);
  }, []);
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick}></div>;
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick}></div>;
  }
  const renderTestimonialContent = () => {
    testimonials.map(({ description, name, link }) => {
      return (
        <div className={classes.testimonialWrapper}>
          <div className={classes.content}>
            <img src={DoubleQuotesIcon} />
            <Typography
              customVariant={
                (isMobileView && "bodySmallRegular") || "h5Regular"
              }
            >
              {description}
            </Typography>
            <Typography
              customVariant={
                (isMobileView && "bodySmallBold") || "bodyLargeBold"
              }
            >
              {name}
            </Typography>

            {link && (
              <a href={link}>
                <Button>View Case Study</Button>
              </a>
            )}
          </div>
        </div>
      );
    });
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    lazyLoad: true,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerMode: true,
          centerPadding: "10px"
        }
      }
    ]
  };
  return (
    <div className={classes.testimonialsWrapper}>
      <div className={classes.headingCTAWrapper}>
        <HeadingCTA
          subheading={"Testimonials"}
          title={"What People Say"}
          center
        />
      </div>
      <Container
        maxWidth={!isMobileView && false}
        className={classes.testimonialContainer}
      >
        <div className={classes.sliderContainer}>
          <Slider asNavFor={nav2State} ref={slider1} fade={true}>
            {testimonials.map(({ picture, name }) => (
              <GatsbyImage image={picture} alt={name} />
            ))}
          </Slider>

          <Slider asNavFor={nav1State} ref={slider2} {...settings}>
            {testimonials.map(({ description, name, link }) => {
              return (
                <div className={classes.testimonialWrapper}>
                  <div className={classes.content}>
                    <img src={DoubleQuotesIcon} alt="double quotes" />
                    <Typography
                      customVariant={
                        (isMobileView && "bodySmallRegular") || "h5Regular"
                      }
                    >
                      {description}
                    </Typography>
                    <Typography
                      customVariant={
                        (isMobileView && "bodySmallBold") || "bodyLargeBold"
                      }
                    >
                      {name}
                    </Typography>

                    {link && (
                      <a href={link}>
                        <Button>View Case Study</Button>
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </Container>
    </div>
  );
};

export default Testimonials;

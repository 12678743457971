import React, { useEffect, Suspense } from "react";
import "../assets/css/main.css";

import HeadingCTA from "../components/HeadingCTA/HeadingCTA";

import Footer from "../components/Footer/Footer";
import Testimonials from "../components/Testimonials/Testimonials";
import Locations from "../components/Locations/Locations";
import CopyRights from "../components/Footer/CopyRights/CopyRights";

import OurClients from "../components/OurClients/OurClients";
import LetsGetStarted from "../components/LetsGetStarted/LetsGetStarted";
import Summary from "../components/CaseStudy/Summary/Summary";
import { StaticImage } from "gatsby-plugin-image";

const IndexPageBelowBanner = ({ data, classes }) => {
  return (
    <>
      <div className={classes.sectionTitle}>
        <HeadingCTA title={""} center />
      </div>

      <Summary
        category="Transport & Logistics"
        title={"We focus on your success"}
        fullHeight={false}
        isFlipped={false}
        customHeight={700}
        image={
          <StaticImage src="../assets/images/events/EVENT_TRANSPORT_HEADER.jpg" />
        }
        link={"/services-overview/"}
        description={
          <>
            Partner with MOTUS | ONE. Our focus is clear: to simplify and
            deliver tangible benefits to every aspect of transport, logistics
            and event-transport management.
            <br />
            <br />
            We’ve never had a templated approach. Instead, we collaborate
            closely with our clients to devise unique project plans that
            optimize our clients’ capabilities and match their ways of working.
          </>
        }
        buttonText="Learn more"
      />

      {/*
      <Summary
        title={"Do everything from one dashboard"}
        fullHeight={true}
        isFlipped={true}
        category="Our Technology"
        image={<StaticImage src="../assets/images/aboutmotus.png" />}
        description={
          <>
            We offer a suite of applications that gives you more visibility,
            better control, and faster reporting and reconciliation. Just as
            important, the entire system is truly easy and intuitive to use. All
            the screens, dashboards and controls somehow “feel familiar”.
          </>
        }
        link={"/rde/"}
        buttonText="Learn more"
      />*/}

      <Locations />
      <OurClients />
      <Testimonials />
      <LetsGetStarted />

      <Footer />
      <CopyRights />
    </>
  );
};

export default IndexPageBelowBanner;

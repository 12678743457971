import React from "react";
import Button from "../Button/Button";
import HeadingCTA from "../HeadingCTA/HeadingCTA";
import Typography from "../Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../helper";
import { Link } from "gatsby";

const useStyles = makeStyles(theme => ({
  root: {
    background:
      "linear-gradient(96.34deg, #FCFCFC 8.92%, #FCFCFC 8.93%, #F8FAFF 100%)",
    padding: "140px 0px",
    marginTop: "120px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "64px 0px 128px 0px",
      marginTop: "74px"
    }
  },
  content: {
    textAlign: "center"
  },
  title: {
    color: "#122044",
    fontSize: "60px",
    lineHeight: "70px",
    letterSpacing: "-0.7px",
    fontWeight: "bold",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      fontSize: "30px",
      lineHeight: "37px"
    }
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#CC4A01",
    marginBottom: "16px"
  },
  description: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "20px",
    padding: "32px 0px"
  }
}));

const LetsGetStarted = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography
          customVariant={"h5Bold"}
          component="span"
          className={classes.subTitle}
        >
          Get in touch
        </Typography>
        <Typography
          customVariant={"h1Bold"}
          component="h2"
          className={classes.title}
        >
          Let’s get started!
        </Typography>
        <Typography
          customVariant={"h5Regular"}
          component="div"
          className={classes.description}
        >
          Partner with us to bring radical new efficiencies to your transport{" "}
          <br />
          and logistics projects.
        </Typography>

        <a href="/contact-us/">
          <Button>Contact Us</Button>
        </a>
      </div>
    </div>
  );
};

export default LetsGetStarted;

import React, { useEffect, Suspense } from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import "../assets/css/main.css";
import MainMenu from "../components/MainMenu/MainMenu";
import MainSlider from "../components/MainSlider/MainSlider";

import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../components/helper";
import IndexPageBelowBanner from "../components/indexbelowbanner";

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    margin: "160px 0 80px 0px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: "40px 0px"
    }
  }
}));

const IndexPage = () => {
  var data = useStaticQuery(query);
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  const classes = useStyles({});
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  var meta = {
    seo: {
      metaTitle: "The Event Transportation Company",
      metaDescription:
        "With combined 50 years of experience, MOTUS | ONE is the single-source provider of professional transportation, event, logistics, and consulting services.",
      reverseSiteName: true
    }
  };

  return (
    <Layout seo={meta.seo} homepage>
      <MainMenu />
      <MainSlider />

      <IndexPageBelowBanner data={data} classes={classes} />
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }

    consultingImage: file(
      relativePath: { eq: "casestudy/Transport_Consulting_Slider.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 675) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;

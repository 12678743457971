import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ImageSlider from "../ImageSlider/ImageSlider";
import HeadingCTA from "../HeadingCTA/HeadingCTA";
import { mobileViewBreakpoint } from "../helper";
import { graphql, useStaticQuery } from "gatsby";

const useStyles = makeStyles(theme => ({
  ourClientsWrapper: {
    marginBottom: "169px",
    overflow: "hidden",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginBottom: "134px",
      marginTop: "128px"
    }
  },
  headingCTAWrapper: {
    marginTop: "160px",
    marginBottom: "206px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginTop: "0px",
      marginBottom: "68px",
      paddingLeft: "16px",
      paddingRight: "16px"
    }
  }
}));

const OurClients = () => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  const data = useStaticQuery(graphql`
    query {
      APIDecImage: file(relativePath: { eq: "clients/apidec-165x120.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      ClubImage: file(relativePath: { eq: "clients/club-165x120.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      DisneyImage: file(relativePath: { eq: "clients/disney-165x120.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      DubaiImage: file(relativePath: { eq: "clients/dubai-165x120.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      F09Image: file(relativePath: { eq: "clients/f09-165x120.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      FalconImage: file(relativePath: { eq: "clients/falcon-165x120.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      FlashImage: file(relativePath: { eq: "clients/flash-165x120.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      MorrisImage: file(relativePath: { eq: "clients/morris-165x120.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      QAHImage: file(relativePath: { eq: "clients/qah-165x120.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      VolvoImage: file(relativePath: { eq: "clients/volvo-165x120.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      YasImage: file(relativePath: { eq: "clients/yas-165x120.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      EuroImage: file(relativePath: { eq: "clients/Euro_Tour Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      DakarImage: file(relativePath: { eq: "clients/Dakar Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      ExtremeImage: file(relativePath: { eq: "clients/Extreme-E Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      MosImage: file(relativePath: { eq: "clients/MOS Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      NBAImage: file(relativePath: { eq: "clients/NBA INDIA.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      SamfImage: file(relativePath: { eq: "clients/SAMF Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  const ourClientsData = [
    {
      picture: data.EuroImage.childImageSharp.gatsbyImageData,
      alt: "European Tour logo"
    },
    {
      picture: data.DakarImage.childImageSharp.gatsbyImageData,
      alt: "Dakar logo"
    },
    {
      picture: data.ExtremeImage.childImageSharp.gatsbyImageData,
      alt: "Extreme logo"
    },
    {
      picture: data.MosImage.childImageSharp.gatsbyImageData,
      alt: "SAM logo"
    },
    {
      picture: data.NBAImage.childImageSharp.gatsbyImageData,
      alt: "NBA logo"
    },
    {
      picture: data.SamfImage.childImageSharp.gatsbyImageData,
      alt: "SAMF logo"
    },
    {
      picture: data.APIDecImage.childImageSharp.gatsbyImageData,
      alt: "apidec logo"
    },
    {
      picture: data.ClubImage.childImageSharp.gatsbyImageData,
      alt: "uae 2009 fifa club world cup logo"
    },
    {
      picture: data.DisneyImage.childImageSharp.gatsbyImageData,
      alt: "disney on ice logo"
    },
    {
      picture: data.DubaiImage.childImageSharp.gatsbyImageData,
      alt: "Dubai Parks logo"
    },

    {
      picture: data.F09Image.childImageSharp.gatsbyImageData,
      alt: "Fifa 2009 logo"
    },
    {
      picture: data.FalconImage.childImageSharp.gatsbyImageData,
      alt: "Falcon logo"
    },
    {
      picture: data.FlashImage.childImageSharp.gatsbyImageData,
      alt: "Flash logo"
    },
    {
      picture: data.MorrisImage.childImageSharp.gatsbyImageData,
      alt: "Philip Morris International logo"
    },
    {
      picture: data.QAHImage.childImageSharp.gatsbyImageData,
      alt: "Qasr al Hosn Festival logo"
    },
    {
      picture: data.VolvoImage.childImageSharp.gatsbyImageData,
      alt: "Volvo logo"
    },
    {
      picture: data.YasImage.childImageSharp.gatsbyImageData,
      alt: "YAS Marina Circuit logo"
    }
  ];

  return (
    <div className={classes.ourClientsWrapper}>
      <div className={classes.headingCTAWrapper}>
        <HeadingCTA
          subheading={"Our Clients"}
          title={"Trusted by some of the world’s best"}
          center
        />
      </div>
      <ImageSlider data={ourClientsData} />
    </div>
  );
};

export default OurClients;
